import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import ServiceGallery from './components/ServiceGallery';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

class ServiceWindows extends Component{
    render(){
        let imageGalleryData = [
            {img: 'service-windows1.png'},
            {img: 'service-windows2.png'},
            {img: 'service-windows3.png'},
            {img: 'service-windows4.png'},
        ];
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Windows Details</h1>
                                    <ul className="page-breadcrumb">
                                        {/* <li>Windows Details</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                {/* service gallery */}
                                <ServiceGallery images={imageGalleryData}/>

                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>Windows</h2>
                                    <p>Our service extends into the installation of the product you ultimately choose. Our installation team are professionals who specialize in correctly setting a full range of fenestration products so that you can expect leak-free, proper function over the long haul.</p>
                                    <p></p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Analysis</h3>
                                    <p>Our estimators start immediately, looking at your plans and speaking with you to understand your needs</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Costing</h3>
                                    <p>We will elaborate a detailed poject proposal to stablish project viability, costs, and  clarify expectations.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Quality</h3>
                                    <p>Always committed to our customers. we guarantee our work.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Always on Time</h3>
                                    <p>Our team is 100% committed. We always will do our best to meet deadlines.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default ServiceWindows;