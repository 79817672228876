import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';

class Projects extends Component{
        state = {
                    projectImages: [
                        "decks4.png",
                        "decks5.png",
                        "decks6.png",
                        "decks7.png",
                        "decks8.png",
                        "decks9.png",
                        "decks10.png",
                        "decks11.png"
                    ]
                }

    render(){
        // const { projectImages } = this.state
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Powder Horn Ranch Details</h1>
                                    <ul className="page-breadcrumb">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Projects section start*/}
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">
                        <div className="project-image"><img src="assets/img/projects/decks00.png" className="img-fluid" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-12 section-space--bottom--30">
                            <div className="project-information">
                                <h3>Project Information</h3>
                                <ul>
                                    <li><strong>Client:</strong> <a href="project-details"> Classic Construcotrs</a></li>
                                    <li><strong>Location:</strong> Round Rock, Texas</li>
                                    <li><strong>Duration:</strong> 4-5 Days</li>
                                    <li><strong>Year:</strong> 2019</li>
                                    <li><strong>Budget:</strong> $8,500</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2> Powder Horn Ranch Boat Dock </h2>
                                <p>In this procject we built a boat dock. A classic composite dock that looks luxurious and attractive. We Installed 2x4 to steel structure and install composite 1x6 trex. We worked on a framing plan and specifications for banding and boat slips, etc. It took us about 5 days to complete the job. </p>
                                <p>Always following blueprints and building plans to meet the needs of clients. We focus on giving you the best quality.</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <PhotoGallery {...this.state}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Projects section end*/}

                </div>

                {/*====================  End of project details page content  ====================*/}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Projects;