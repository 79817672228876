import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabTwo extends Component{

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            { tabMenuName: 'Framing'},
            { tabMenuName: 'Cornice'},
            { tabMenuName: 'Windows'},
            { tabMenuName: 'Renovations'},
            { tabMenuName: 'Decks'},
            { tabMenuName: 'Porches'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "framin1.png", contentTitle: 'Framing', contentDesc: 'We know that you are expecting the best when it comes to invesment. At Top Notch we build strong structures and supervise the contruction process at all times.  ', serviceLink: 'service-framing'},
            {bgUrl: "cornice3.png", contentTitle: 'Cornice', contentDesc: 'Our experience ensures you a quality job. Cornices are available in a large number of styles and sizes. Straight, radius or arched, whatever you require we will produce it.', serviceLink: 'service-cornice'},
            {bgUrl: "windows6.png", contentTitle: 'Windows', contentDesc: 'Our trained installers can install all types of windows.', serviceLink: 'service-windows'},
            {bgUrl: "renovation3.png", contentTitle: 'Renovations', contentDesc: 'Our fully trained and certified staff will walk with you through each step of the property renovation process. We are more than happy to respond quickly to all of your questions and concerns along the way.', serviceLink: 'service-renovations'},
            {bgUrl: "deck2.png", contentTitle: 'Decks', contentDesc: "We don't have limits at Top Notch, we have the ability to build decks of any size and anywhere.", serviceLink: 'service-decks'},
            {bgUrl: "porche2.png", contentTitle: 'Porches', contentDesc: 'We guarantee the best quality on porches, we know which pieces of hardware to use and how to install them the right way.', serviceLink: 'service-porches'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title-area text-center section-space--bottom--50">
                                    <h2 className="section-title">Our Services</h2>
                                    <p className="section-subtitle"></p>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                {/* service tab wrapper */}
                                
                                <div className="service-tab-wrapper">
                                <Tabs>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <TabList>
                                                <div className="service-tab__link-wrapper">
                                                    {serviceTabMenuDatalist}
                                                </div>
                                            </TabList>
                                        </div>

                                        <div className="col-md-8">
                                            {serviceTabContentDatalist}
                                        </div>
                                    </div>
                                </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabTwo;