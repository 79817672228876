import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Home from './HomeThree';
import About from './pages/About';
import { 
    ServiceFraming, 
    ServiceWindows,
    ServiceRenovations,
    ServiceDecks,
    ServiceCornice,
    ServicePorches
} from './service';
// import Projects from './project/Projects';
import ProjectDetails from './project/ProjectDetails';
import ProjectDeck from './project/ProjectDeck';
import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-framing`} component={ServiceFraming}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-windows`} component={ServiceWindows}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-renovations`} component={ServiceRenovations}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-decks`} component={ServiceDecks}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-cornice`} component={ServiceCornice}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-porches`} component={ServicePorches}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/project-details`} component={ProjectDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/project-deck`} component={ProjectDeck}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound}/>
                    <Route component={NoMAtch} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.register();