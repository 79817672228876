import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import ServiceGallery from './components/ServiceGallery';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

class ServicePorches extends Component{
    render(){
        let imageGalleryData = [
            {img: 'service-porches1.png'},
            {img: 'service-porches2.png'},
            {img: 'service-porches3.png'},
            {img: 'service-porches4.png'},
    
        ]

        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Porches Details</h1>
                                    <ul className="page-breadcrumb">
                                        {/* <li>Porches Details</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                {/* service gallery */}
                                <ServiceGallery images ={imageGalleryData} />

                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>Porches</h2>
                                    <p>Always committed to our clients we specialize in building porches with solid structures to provide you with the best quality, we focus on every detail of porch construction and we have the necessary work tools to ensure that you receive the best results.</p>
                                    <p></p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Analysis</h3>
                                    <p>Our estimators start immediately, looking at your plans and speaking with you to understand your needs.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Costing</h3>
                                    <p>We will elaborate a detailed poject proposal to stablish project viability, cost, and  clarify expectations.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Quality</h3>
                                    <p>Always committed to our customers. we guarantee our work.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Always on Time</h3>
                                    <p>Our team is 100% committed. We always will do our best to meet deadlines.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default ServicePorches;