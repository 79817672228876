import React, { Component } from 'react';

class AboutText extends Component{

    render(){
        return(
            <div>
                {/*====================  about text area ====================*/}
                <div className="about-text-area grey-bg section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="video-cta-content">
                                <h4 className="video-cta-content__small-title">About Us</h4>
                                <h3 className="video-cta-content__title"> Offering Only the Best Quality Carpentry Services</h3>
                                <p className="video-cta-content__text"> At Top Notch we believe that high-end quality work and efficiency go hand in hand. With over 18 years of experience in construction carpentry work, we pride ourselves on the fact that we are not limited, we count with qualified personnel and the necessary working tools to meet our clients highest expectations.</p>
                                <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--round">CONTACT US</a>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                {/*====================  End of about text area  ====================*/}
            </div>
        )
    }
}


export default AboutText;