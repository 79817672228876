import React, { Component } from 'react';

class FeatureIconText extends Component {
    render() {

        let data = [
            {featureIcon: "medal.png", featureTitle: "We Deliver Quality", featureDescription: "Our quality is backed and guaranteed. Our degree of excellence is what makes us different from our competitors.  ."},
            // {featureIcon: "feature-5.png", featureTitle: "Always On Time", featureDescription: "On time, every time."},
            {featureIcon: "saw.png", featureTitle: "We Are Passionate", featureDescription: "Our staff are fully equipped and qualified, their knowledge and experience allows us to approach any project with confidence."},
            {featureIcon: "analysis.png", featureTitle: "Top Notch Results", featureDescription: "Providing an end product that not only meets our customers expectations, but far exceeds them."}
        ];

        let Datalist = data.map((val, i)=>{
            return(
         
                <div className="col-lg-4 col-md-6" key={i}>
                    <div className="single-feature-icon">
                        <div className="single-feature-icon__image">
                            <img src={`assets/img/icons/${val.featureIcon}`} className="img-fluid" alt="" />
                        </div>
                        <h3 className="single-feature-icon__title">{val.featureTitle}</h3>
                        <p className="single-feature-icon__content">{val.featureDescription}</p>
                    </div>
                </div>
            )
        });

        return (
            <div>
                {/*====================  feature icon area ====================*/}
                <div className="feature-icon-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-icon-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of feature icon area  ====================*/}
            </div>
        )
    }
}

export default FeatureIconText;
