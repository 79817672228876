import React, { Component } from 'react';
import invoicePdf from '../../../public/assets/files/Invoice.pdf'


class Sidebar extends Component{

    render(){

        let data = [
            {sidebarListLink: "service-framing", sidebarListText: 'Framing'},
            {sidebarListLink: "service-cornice", sidebarListText: 'Cornice'},
            {sidebarListLink: "service-windows", sidebarListText: 'Windows'},
            {sidebarListLink: "service-renovations", sidebarListText: 'Renovations'},
            {sidebarListLink: "service-decks", sidebarListText: 'Decks'},
            {sidebarListLink: "service-porches", sidebarListText: 'Porches'}
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Services</h3>
                        <ul className="sidebar-list">
                            {Datalist}
                        </ul>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">See Sample Invoice</h3>
                        <ul className="sidebar-list">
                            <li><a href = {invoicePdf} target = "_blank"><i className="fa fa-file-pdf-o" />Invoice Sample</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;