import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
import framingClip from '../../public/assets/clips/framingClip.mp4'
class About extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--120">
                    <div className="container">
                        <div className="row row-25 align-items-center">
                        <div className="col-lg-6 col-12 mb-30">
                            <div className="about-image-two">
                                <video className='videoTag' autoPlay loop>
                                    <source src={framingClip} type='video/mp4' />
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mb-30">
                            <div className="about-content-two">
                            <h3>Welcome to Top Notch</h3>
                            <h1>18 Years of Industry Experience</h1>
                            <h4>We are ready to build your dream property. No project is too small nor too big for us, we offer the same Top Notch quality on all jobs.</h4>
                            <p>We are licensed, insured and OSHA approved. We are a fully established company located in Austin Texas. Our work ranges from small residential renovations to multi-level building framing structures. We are certified windows installers and we work on all kinds of cornice, decks, and patio renovation. We do all kinds of wood and hardie siding. We have worked with many top builders in and around the Austin area. Our Knowledge and experience allows us to approach any type of project with confidence. </p>
                            <a href="service-framing" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Services</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                <FeatureIcon background = "grey-bg"/>
                </div>

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;