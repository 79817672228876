import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';
class Projects extends Component{

    state = {
        projectImages: [
            "details1.png",
            "details2.png",
            "details3.png",
            "details4.png",
            "details5.png",
            "details6.png",
            "details7.png",
            "details8.png"
        ]
    }


    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Peach Creek Farm Details</h1>
                                    <ul className="page-breadcrumb">
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Projects section start*/}
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">
                        <div className="project-image"><img src="assets/img/projects/details00.png" className="img-fluid" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-12 section-space--bottom--30">
                            <div className="project-information">
                                <h3>Project Information</h3>
                                <ul>
                                    <li><strong>Client:</strong> <a href="project-details"> Classic Construcotrs</a></li>
                                    <li><strong>Location:</strong> Spicewood, Texas</li>
                                    <li><strong>Duration:</strong> 12 weeks</li>
                                    <li><strong>Year:</strong> 2019</li>
                                    <li><strong>Budget:</strong> $165,000</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2> Peach Creek Farm </h2>
                                <p>The planning, coordination, and execution of a construction project is not easy. It requires strong skills, techniques, and deep knowledge of the building process. For that reason we are very proud to share with you one of our latest projects. In this project we did remodeling and framing, we adjusted window and door openings to receive new steel inits, and we installed all alluminun clad wood windows. It took us about 12 weeks to complete the job. </p>
                                <p>Always following blueprints and building plans to meet the needs of clients. We focus on giving you the best quality.</p>
                                <p> </p>
                            </div>
                        </div>
                        <div className="col-12">
                            <PhotoGallery {...this.state}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Projects section end*/}

                </div>

                {/*====================  End of project details page content  ====================*/}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Projects;